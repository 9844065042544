import React, {useState} from 'react';
import '../../scss/components/video.scss'
import {Parallax} from "react-scroll-parallax";

export default function Video() {

    const [showVideo, setShowVideo] = useState(false);

    const handlePlayButtonClick = () => {
        setShowVideo(true);
    }

  return (
      <section className='video' name="test1">
          <div className="video__content">
              <img src="/images/video/video.png" className={'video-layer'} alt="video"/>
              {!showVideo && <img src="/images/video/play.png" alt="play" className="video__play-button"
                                  onClick={handlePlayButtonClick}/>}
              {showVideo &&
                  <iframe src="https://www.youtube.com/embed/w7KN1WKmNtU?autoplay=1" allow="autoplay; encrypted-media"
                          allowFullScreen allow='autoplay'></iframe>}
          </div>
      </section>
  )
}
