import React from "react";
import "../../scss/components/battlemech.scss";
import {Parallax} from "react-scroll-parallax";

import BattleIcon from '../../images/battlemesh/photo1.png'
import Line1 from '../../images/battlemesh/Line_7.png'
import Line2 from '../../images/battlemesh/Line_8.png'



export default function Battlemech() {

  return (
      <Parallax className={'parallax'} opacity={[2.5, -0.1]}>
          <section className="battlemech">
              {/*<h1  className="battlemech__title title-purpl">ANCIENT BATLEMECHS</h1>*/}
              <div className="battlemech__content">
                <div className="battlemech__img">
                    <img src={BattleIcon} alt=""/>
                </div>
                  <div className="battlemech__text">
                      <div className="line-horizontal">
                          <img src={Line1} alt=""/>
                      </div>
                        <div className="line-vertical">
                            <img src={Line2} alt=""/>
                        </div>
                      <h2>Ruthenian superheavy Battlemech</h2>
                        <p><span>These</span> battle machines were created thousand years ago using Void
                            Gods technologies.</p>
                        <p><span>It's</span> impossible to recreate such machinery that’s why they are
                            enormously expensive and their number is limited.</p>
                        <p><span>Battlemech</span> can be destroyed only by other battlemech.</p>
                        <p><span>You</span> will manage light, medium, heavy and extra rare behemoths –
                            superheavy battlemechs.</p>
                  </div>
              </div>
          </section>
      </Parallax>
  );
}
