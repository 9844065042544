import React from "react";
import "../../scss/components/why.scss";
import {Parallax} from "react-scroll-parallax";

import Img1 from '../../images/why/image_1.png';
import Img2 from '../../images/why/image_2.png';
import Img3 from '../../images/why/image_3.png';
import Img4 from '../../images/why/image_4.png';
import BtcIcon from '../../images/why/btc_icon.png';
import VideoIcon from '../../images/why/video_icon.png';
import NFTIcon from '../../images/why/nft_icon.png';


export default function Why() {
  return (
      <Parallax className={'parallax'} opacity={[2.5, -0.1]}>
        <section name={'test0'} className="why">
          <div className="why__container">
            <Parallax className={'parallax'} translateY={[0, -40]}>
              <h1 className="why__title title-gold">
                WHY YOU SHOULD PLAY VOID GODS?
              </h1>
            </Parallax>
            <div className="why__content">
              <div className="why__content-item">
                <div className="img">
                  <img src={Img1} alt=""/>
                </div>
                <div className="layaut">
                  <div className="btc-icon">
                    <img src={BtcIcon} alt=""/>
                  </div>
                  <div className="layaut_text">
                    <p>New pool system, you can call it P2E 2.0</p>
                  </div>
                </div>
              </div>

              <div className="why__content-item-third">
                <div className="img">
                  <img src={Img2} alt=""/>
                </div>
                <div className="layaut">
                  <div className="btc-icon">
                    <img src={BtcIcon} alt=""/>
                  </div>
                  <div className="layaut_text">
                    <p>Each robot, weapon or pilot can be sold or burnt in exchange of BTC from the pool</p>
                  </div>
                </div>
              </div>

              <div className="why__content-item-second">
                <div className="img">
                  <img src={Img3} alt=""/>
                </div>
                <div className="layaut">
                  <div className="btc-icon">
                    <img src={VideoIcon} alt=""/>
                  </div>
                  <div className="layaut_text">
                    <p>Uniqe battle system. They are fighting - you are watching!</p>
                  </div>
                </div>
              </div>

              <div className="why__content-item-third">
                <div className="img">
                  <img src={Img4} alt=""/>
                </div>
                <div className="layaut">
                  <div className="btc-icon">
                    <img src={NFTIcon} alt=""/>
                  </div>
                  <div className="layaut_text">
                    <p>50% of all NFT sales will go into NFT  pool, which means this will setup effective floor price for all NFT types. Each NFT can be put back into a pool and exchanged into appropriate amount of BTC.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Parallax>
  );
}
