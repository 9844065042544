import React from 'react';
import '../../scss/components/footer.scss';
import {Link} from "react-scroll";

export default function Footer() {
  return (
    <footer className="footer">
        <div className="footer__top">
            <img src="/images/header/logo.png" alt="void gods" />
            <div className="footer__top-link">
                <ul>
                    <Link
                        activeClass="active"
                        className="test0"
                        to="test0"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                    >
                        <li>Features</li>
                    </Link>
                    <Link
                        activeClass="active"
                        className="test1"
                        to="test1"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                    >
                        <li>Teaser</li>
                    </Link>
                    <Link
                        activeClass="active"
                        className="test2"
                        to="test2"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                    >
                        <li>How to earn</li>
                    </Link>
                    <Link
                        activeClass="active"
                        className="test3"
                        to="test3"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                    >
                        <li>NFTs</li>
                    </Link>
                    <Link
                        activeClass="active"
                        className="test4"
                        to="test4"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                    >
                        <li>Roadmap</li>
                    </Link>
                    <Link
                        activeClass="active"
                        className="test5"
                        to="test5"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                    >
                        <li>Lore</li>
                    </Link>
                    <Link
                        activeClass="active"
                        className="test6"
                        to="test6"
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={0}
                    >
                        <li>Factions</li>
                    </Link>
                </ul>
            </div>
            <div className="footer__top-socials">
                <a href="#"><img src="/icons/discordGray.png" alt="discord" /></a>
                <a href="#"><img src="/icons/twitterGray.png" alt="twitter" /></a>
            </div>
        </div>
        <div className="footer__bottom">
            <p>Void Gods, 2023</p>
            {/*<div className="footer__bottom-socials">*/}
            {/*    <a href="#"><img src="/icons/discordGray.png" alt="discord" /></a>*/}
            {/*    <a href="#"><img src="/icons/twitterGray.png" alt="twitter" /></a>*/}
            {/*</div>*/}
        </div>
    </footer>
  )
}
