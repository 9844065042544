import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';
import '../../scss/components/preview.scss';


export default function Preview() {

    return (
        <Parallax opacity={[2, -0.1]}>
            <section className="preview">
                <div className="preview__overlay"></div>
                <video autoPlay loop muted>
                    <source src={'https://voidofgods.s3.amazonaws.com/void+gods+main+screen.mp4'} type="video/mp4" />
                </video>
                <Fade bottom>
                <div className="preview__content">
                        <div className="preview__img">
                            <img src="/images/preview/logoBig.png" alt="logo" />
                        </div>
                    <h2 className="preview__title">
                        First Bitcoin NFT’s based web3 game
                    </h2>
                </div>
            </Fade>
            </section>
        </Parallax>
    );
}
