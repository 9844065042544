import React, {useState} from 'react';
import { factions } from '../../data/factionsData';
import '../../scss/components/factions.scss';
import Faction from './Faction';

export default function Factions() {
    const [isMore, setIsMore] = useState(false);
 

  return (
          <section name={'test6'} className="factions" >
              <h1 className="factions__title title-gold">
                  void gods factions
                  <button className='factions__btn more' onClick={() => setIsMore(!isMore)} >
                      <img src={isMore ?`/icons/collapseUp.svg`: `/icons/collapseDown.svg`} alt="btn" />
                  </button>
              </h1>
              {isMore &&
                  <div className="factions__content">
                      {factions.map(({id, title, text, color}) => {
                          return <Faction key={id}  id={id} title={title} text={text} color={color} />
                      })}
                  </div>
              }

          </section>
  )
}
