import React from 'react';

import {
    Link,
} from "react-scroll";

import CloseIcon from "../../images/top_navigation.png";

import '../../scss/components/header.scss';

function HeaderScroll() {
    return (
        <header className='header'>
            <div className="close-menu">
                <img src={CloseIcon} alt="" />
            </div>
            <nav className="header__menu">
                <ul>
                    <li>
                        {/*<Link href="#">Features</Link>*/}
                        <Link
                            activeClass="active"
                            className="test0"
                            to="test0"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={0}
                        >
                            Features
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            className="test1"
                            to="test1"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={0}
                        >
                            Teaser
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            className="test2"
                            to="test2"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={0}
                        >
                            How to Earn
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            className="test3"
                            to="test3"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={220}
                        >
                            NFTs
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            className="test4"
                            to="test4"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={0}
                        >
                            Roadmap
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            className="test5"
                            to="test5"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={0}
                        >
                            Lore
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            className="test6"
                            to="test6"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={0}
                        >
                            Factions
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className="header__info">
                <div className="header__socials">
                    <a href="#">
                        <img src="/icons/twitter.png" alt="twitter" className="twitter" />
                    </a>
                    <a href="#">
                        <img src="/icons/discord.png" alt="discord" className="" />
                    </a>
                </div>
                <button className="header__list-btn">
                    Join early
                </button>
            </div>
        </header>
    )
}

export default HeaderScroll;
