import React from 'react';
import '../../scss/components/pilots.scss';
import {Parallax} from "react-scroll-parallax";
import Pilot1 from '../../images/pilots/femaleLegendary.png'
import Pilot2 from '../../images/pilots/maleLegendary.png'




export default function Pilots() {
  return (
      <Parallax className={'parallax'} opacity={[3, -0.1]}>
          <section className="pilots">
              <div className="pilots-title title-purpl">
                  <h1>EXPERIENCED PILOTS</h1>
              </div>
              <div className="pilots__content">
                  <div className="pilots__content-text">
                      <p><span>Battlemechs</span> are extremely rare. However, people are able to control that monsters are much more infrequent. </p>
                      <p><span>There</span> are no 2 equal pilots in the galaxy. Each of them has unique set of skills. Combine them to achieve the most effective synergy.</p>
                      <p><span>Pilots</span> are divided by their experience. The most skilled are well-known like rock stars.</p>
                  </div>
                  <div className="pilots__content-img">
                      <div className="first">
                          <img src={Pilot1} alt=""/>
                      </div>
                      <div className="second">
                          <img src={Pilot2} alt=""/>
                      </div>
                  </div>
              </div>
          </section>
      </Parallax>
  )
}
