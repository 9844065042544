export const stages = [
    {   
        title: "Stage 1. Q1-Q2 2023",
        subtitle: "",
        list: ["Concept art development","Game design development"],
        id: 1
    },
    {
        title: "Stage 2. Q3 2023",
        subtitle: "",
        list: ["Initial NFT Drops: Pilots","Weapons Drop","New Robots design and presentations"],
        id: 2

    },
    {

        title: "Stage 3. Q4 2023",
        subtitle: "",
        list: ["Token Drop via snapshot for NFTc", "Holders Global quests and event system", "Robots sale(initial)"],
        id: 3

    },
    {
        title: "Stage 4. Q1 2024",
        subtitle: "",
        list: ["PVP system, MVP version","2nd Token Drop via snapshot for NFT Holders","Cinematic video reports early concept"],
        id: 4

    },
    {
        title: " Stage 5. Q2 2024",
        subtitle: "",
        list: ["PVE monster battles","Battle System Alpha version","Lands introduction and NFT Drop","First Season Plot"],
        id: 5

    }

]
