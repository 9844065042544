import {useEffect, useState} from "react";

import MenuIcon from '../src/images/menu_icon.png'


import {
  Battlemech,
  Discord,
  Factions,
  Footer,
  Header,
  HowEarn,
  Little,
  Origin,
  Pilots,
  Planets,
  Preview,
  Roadmap,
  Video,
  Weapons,
  Why,
  HeaderScroll
} from "./components";
import "./scss/index.scss";
import {ParallaxProvider} from "react-scroll-parallax";
import AOS from "aos";
import "aos/dist/aos.css";


function App() {

    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [isScrollMenuOpen, setIsScrollMenuOpen] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const viewportHeight = window.innerHeight;

            if (scrollPosition > viewportHeight) {
                setIsHeaderVisible(false);
                setIsMenuVisible(true)
            } else {
                setIsHeaderVisible(true);
                setIsMenuVisible(false)
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);


  return (
    <div className="app">
        {isMenuVisible && !isScrollMenuOpen &&
            <div className={"scroll-menu"} onClick={() => setIsScrollMenuOpen(!isScrollMenuOpen)}>
                <img src={MenuIcon} alt=""/>
            </div>
        }
        <ParallaxProvider>
            {isHeaderVisible && <Header />}
            {isScrollMenuOpen && <HeaderScroll />}
            <Preview />
            <Why />
            <Video />
            <HowEarn/>
            <Little/>
            <Battlemech/>
            <Pilots/>
            <Weapons/>
            <Planets/>
            <Roadmap/>
            <Origin/>
            <Factions/>
            <div className="animation-video">
                <div className="video__overlay"></div>
                <video className="video-background" autoPlay loop muted>
                    <source src={'https://voidofgods.s3.amazonaws.com/void_gods+animation.mp4'} type="video/mp4" />
                </video>
                <Discord />
                <Footer />
            </div>
        </ParallaxProvider>
    </div>
  );
}

export default App;
