import React from 'react';
import '../../scss/components/how_earn.scss';
import {Parallax} from "react-scroll-parallax";

export default function HowEarn() {
  return (
      <section name={'test2'} className="how-earn">
          <h1 className="how-earn__title title-gold">
              HOW TO EARN?
          </h1>
          <div className="how-earn__content">
              <div className="how-earn__gallery">
                  <div className="how-earn__gallery-row">
                      <div className="hero">
                          <img src="/images/earn/FemaleCommon.png" alt="hero" />
                      </div>
                      <div className="hero">
                          <img src="/images/earn/FemaleRare.png" alt="hero" />
                      </div>
                      <div className="hero">
                          <img src="/images/earn/FemaleEpic.png" alt="hero" />
                      </div>
                  </div>
                  <div className="how-earn__gallery-row">
                      <div className="hero">
                          <img src="/images/earn/maleCommon.png" alt="hero" />
                      </div>
                      <div className="hero">
                          <img src="/images/earn/maleRare.png" alt="hero" />
                      </div>
                      <div className="hero">
                          <img src="/images/earn/maleEpic.png" alt="hero" />
                      </div>
                  </div>
              </div>
              <div className="how-earn__info">
                  <p className={'cursive'}><span>Humankind</span> becomes hegemony ofthe galaxy after discovering Void Amber.
                      Cryptic crystals left by VOID GODS billions years ago.
                      Essential older than our universe. </p>
                  <p className={'text'}>
                      <span>There</span> is limited amount of Void Amber, so you should hurry up to earn it.
                  </p>

                  <p className={'text-2'}><span>Methods</span> to get Void Amber:</p>

                  <ul className="methods-list">
                      <li>Resolve quests of noble houses. </li>
                      <li>Higher loyalty guaranties good reward </li>
                      <li>Sell any item to other players on the global market  </li>
                      <li>Mine it from your planets or attack foreign</li>
                      <li>Win Void Amber during tournaments </li>

                  </ul>
              </div>
          </div>
      </section>
  )
}
